import "./App.css";
import React, { Fragment, useEffect, useRef, useState } from "react";
import UiDimensions from "./context/UiDimensions";
import Home from "./pages/home/Home";
import Service from "./pages/service/Service";
import Contact from "./pages/contact/Contact";
import Jobs from "./pages/jobs/Jobs";
import Portfolio from "./pages/portfolio/Portfolio";
import Loading from "./components/loading/Loading";
import CV from "./pages/cv/CV";

const App = () => {
  const Width = UiDimensions().innerWidth;
  const Height = UiDimensions().innerHeight;
  const refService = useRef(null);
  const refHome = useRef(null);
  const refJobs = useRef(null);
  const refGallery = useRef(null);
  const refCV = useRef(null);
  const refContact = useRef(null);
  const [seconds, setSeconds] = useState(0);
  const scrollService = () => {
    if (refService.current) {
      refService.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollHome = () => {
    if (refHome.current) {
      refHome.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollJobs = () => {
    if (refJobs.current) {
      refJobs.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollGallery = () => {
    if (refGallery.current) {
      refGallery.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollContact = () => {
    if (refContact.current) {
      refContact.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollCV = () => {
    if (refCV.current) {
      refCV.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const timerId = setInterval(() => {
      if (seconds === 3) {
        clearInterval(timerId);
        return;
      }
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [seconds]);

  return (
    <Fragment>
      {seconds === 3 ? (
        <Fragment>
          <Home
            W={Width}
            H={Height}
            scrollService={scrollService}
            scrollHome={scrollHome}
            refHome={refHome}
            scrollGallery={scrollGallery}
            scrollContact={scrollContact}
            scrollCV={scrollCV}
          />
          <Service
            W={Width}
            H={Height}
            refService={refService}
            scrollJobs={scrollJobs}
            scrollContact={scrollContact}
          />
          <Jobs W={Width} H={Height} refJobs={refJobs} />
          <Portfolio W={Width} H={Height} refGallery={refGallery} />
          <CV W={Width} H={Height} refCV={refCV} />
          <Contact W={Width} H={Height} refContact={refContact} />
        </Fragment>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};

export default App;
