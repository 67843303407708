import { Box } from "@mui/material";
import React, { Fragment } from "react";
import classess from "./Lines.module.css";

const Lines = ({ W }) => {
  return (
    <Box className={classess.JobsLine}>
      <Box
        className={classess.JL01}
        sx={{ width: W <= 560 ? "30%" : W >= 561 && W <= 768 ? "20%" : "15%" }}
      ></Box>
      <Box
        className={classess.JL01}
        sx={{ width: W <= 560 ? "30%" : W >= 561 && W <= 768 ? "20%" : "15%" }}
      ></Box>
      <Box
        className={classess.JL01}
        sx={{ width: W <= 560 ? "30%" : W >= 561 && W <= 768 ? "20%" : "15%" }}
      ></Box>
      {W <= 560 ? null : (
        <Box
          className={classess.JL01}
          sx={{ width: W >= 561 && W <= 768 ? "20%" : "15%" }}
        ></Box>
      )}
      {W <= 768 ? null : (
        <Fragment>
          <Box className={classess.JL01} sx={{ width: "15%" }}></Box>
          <Box className={classess.JL01} sx={{ width: "15%" }}></Box>
        </Fragment>
      )}
    </Box>
  );
};

export default Lines;
