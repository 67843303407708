import { Button } from "@mui/material";
import React from "react";

const ButtonIcon = ({
  Text,
  Color,
  Font,
  FontSize,
  FontWeight,
  BorderRadius,
  TextTransform,
  Width,
  Height,
  LetterSpacing,
  MarginTop,
  Variant,
  MarginRight,
  MarginLeft,
  OnClick,
  MarginBottom,
}) => {
  return (
    <Button
      sx={{
        width: Width,
        height: Height,
        backgroundColor: Color,
        textTransform: TextTransform,
        fontFamily: Font,
        fontSize: FontSize,
        fontWeight: FontWeight,
        borderRadius: BorderRadius,
        boxShadow: "0em 0em 0em #FFF",
        "&:hover": {
          backgroundColor: Color,
          boxShadow: "0em 0em 0em #FFF",
        },
        letterSpacing: LetterSpacing,
        marginTop: MarginTop,
        marginRight: MarginRight,
        marginLeft: MarginLeft,
        marginBottom: MarginBottom,
      }}
      variant={Variant}
      onClick={OnClick}
    >
      {Text}
    </Button>
  );
};

export default ButtonIcon;
