import React, { Fragment } from "react";
import classess from "./Contact.module.css";
import { Box } from "@mui/material";
import Arrow from "../../assets/arraow2.png";
import locales from "../../context/locales.json";
import Lines from "../../components/lines/Lines";
import Social from "../../components/Social/Social";

const Contact = ({ refContact, W }) => {
  return (
    <Fragment>
      <Lines W={W} />
      <Box className={classess.Contact} ref={refContact}>
        <Box className={classess.Co1}></Box>
        <Box className={classess.Co2}></Box>
        <Box className={classess.Co3}>
          <span>Copyright C 2024 Parhamco.pages.dev. Allrights Reserved.</span>
          <span>
            Created by <abbr title="">Ramin Maghsoudi</abbr>
          </span>
        </Box>
        <Box className={classess.Co4}>
          <Box className={classess.Pic}></Box>
          <img src={Arrow} alt="ARROW" className={classess.Arrow} />
          <Box className={classess.Dat}>
            <Box className={classess.Dat1}>
              <span>{locales.MIND}</span>
              <span>
                {locales.IDEA} <span>{locales.US}</span>
              </span>
            </Box>
            <Box className={classess.Dat2}>
              <Box className={classess.Cont}>
                <Box className={classess.Line}></Box>
                <span className={classess.Tel1}>{locales.TEL1}</span>
                <Box className={classess.Line}></Box>
                <span className={classess.Tel1}>{locales.TEL2}</span>
                <Box className={classess.Line}></Box>
                <span className={classess.Tel2}>
                  <label className={classess.AD1}>{locales.ADDRESS1}</label>{" "}
                  <label className={classess.AD2}>{locales.ADDRESS2}</label>{" "}
                  <label className={classess.AD3}>{locales.ADDRESS3}</label>{" "}
                  <label className={classess.AD4}>{locales.ADDRESS4}</label>{" "}
                  <label className={classess.AD5}>{locales.ADDRESS5}</label>{" "}
                  <label className={classess.AD6}>{locales.ADDRESS6}</label>
                </span>
                <Box className={classess.Social}>
                  <Social
                    MT={W <= 768 ? "0px" : "15px"}
                    ML={W <= 768 ? "0px" : "100px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Contact;
