import React from "react";
import classess from "./Service.module.css";
import { Box } from "@mui/material";
import ButtonIcon from "../../ui/button/ButtonIcon";
import Arrow from "../../assets/arraow.png";
import AirConditioner from "../../assets/AirConditioner.webp";
import Signature from "../../assets/Signature.png";
import locales from "../../context/locales.json";

const Service = ({ refService, scrollJobs, scrollContact }) => {
  return (
    <Box className={classess.Service} ref={refService}>
      <Box className={classess.Info}>
        <Box className={classess.Data}>
          <Box className={classess.DataLine}>
            <span>درباره ما</span>
          </Box>
          <span className={classess.Data1}>{locales.ABOUT1}</span>
          <span className={classess.Data1}>{locales.ABOUT2}</span>
          <img src={Signature} alt="SIGNATURE" className={classess.Signature} />
          <Box className={classess.DataBotton}>
            <ButtonIcon
              Text={<img src={Arrow} alt="ARROW" />}
              Color="#443e58"
              Font="A"
              FontSize="1.2rem"
              FontWeight="normal"
              BorderRadius="0px"
              TextTransform="none"
              Width="110px"
              Height="35px"
              LetterSpacing="-1px"
              MarginTop="15px"
              Variant="contained"
              MarginRight="25px"
              MarginLeft="0px"
              OnClick={scrollJobs}
            />
            <ButtonIcon
              Text="تماس با ما"
              Color="#ff001f"
              Font="A"
              FontSize="1.2rem"
              FontWeight="normal"
              BorderRadius="0px"
              TextTransform="none"
              Width="110px"
              Height="35px"
              LetterSpacing="-1px"
              MarginTop="15px"
              Variant="contained"
              MarginRight="0px"
              MarginLeft="0px"
              OnClick={scrollContact}
            />
          </Box>
        </Box>
        <Box className={classess.Vanet}>
          <img src={AirConditioner} alt="VANET" />
        </Box>
      </Box>
      <Box className={classess.Items}>
        <Box className={classess.ItemsLeft}>
          <Box className={classess.I3}>
            <Box className={classess.IInfo}>
              <span>100%</span>
              <span>
                {locales.PRE_TITLE02} {locales.TITLE03}
              </span>
            </Box>
            <Box className={classess.Percent}>
              <Box></Box>
            </Box>
          </Box>
          <Box className={classess.I4}>
            <Box className={classess.IInfo}>
              <span></span>
              <span></span>
            </Box>
            <Box className={classess.Percent4}></Box>
          </Box>
        </Box>
        <Box className={classess.ItemsRight}>
          <Box className={classess.I1}>
            <Box className={classess.IInfo}>
              <span>100%</span>
              <span>
                {locales.PRE_TITLE01} {locales.TITLE01}
              </span>
            </Box>
            <Box className={classess.Percent}>
              <Box></Box>
            </Box>
          </Box>
          <Box className={classess.I2}>
            <Box className={classess.IInfo}>
              <span>100%</span>
              <span>
                {locales.PRE_TITLE01} {locales.TITLE02}
              </span>
            </Box>
            <Box className={classess.Percent}>
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Service;
