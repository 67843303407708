import React, { Fragment, useState } from "react";
import classess from "./Jobs.module.css";
import { Box } from "@mui/material";
import Lines from "../../components/lines/Lines";
import locales from "../../context/locales.json";
import ItemService from "../../components/itemservice/ItemService";

const Jobs = ({ refJobs, W, H }) => {
  const [Select, setSelect] = useState(1);
  return (
    <Fragment>
      <Lines W={W} />
      <Box className={classess.Jobs} ref={refJobs}>
        <span className={classess.Title}>{locales.SERVICE_TITLE}</span>
        <span className={classess.SubTitle}>{locales.SERVICE_SUBTITLE}</span>
        <Box className={classess.Items}>
          <ItemService Icon="3" W={W} Select={Select} setSelect={setSelect} />
          <ItemService Icon="2" W={W} Select={Select} setSelect={setSelect} />
          <ItemService Icon="1" W={W} Select={Select} setSelect={setSelect} />
        </Box>
        <Box className={classess.Circles}>
          <Box
            className={classess.Circle}
            sx={{
              width: Select === 3 ? "30px" : "9px",
              borderRadius: Select === 3 ? "10px" : "50%",
            }}
          ></Box>
          <Box
            className={classess.Circle}
            sx={{
              width: Select === 2 ? "30px" : "9px",
              borderRadius: Select === 2 ? "10px" : "50%",
            }}
          ></Box>
          <Box
            className={classess.Circle}
            sx={{
              width: Select === 1 ? "30px" : "9px",
              borderRadius: Select === 1 ? "10px" : "50%",
            }}
          ></Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Jobs;
