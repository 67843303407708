import React from "react";
import classess from "./ItemService.module.css";
import { Box } from "@mui/material";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import FlareRoundedIcon from "@mui/icons-material/FlareRounded";
import ShowerRoundedIcon from "@mui/icons-material/ShowerRounded";
import Comma from "../../assets/Comma.png";
import locales from "../../context/locales.json";

const ItemService = ({ Icon, W, Select, setSelect }) => {
  const OnClick = (ICON) => {
    if (ICON === "1") setSelect(1);
    if (ICON === "2") setSelect(2);
    if (ICON === "3") setSelect(3);
  };
  return (
    <Box
      className={classess.Item}
      sx={{
        position: Icon === "3" && W >= 561 && W <= 850 ? "absolute" : null,
        marginLeft:
          Icon === "2" && W >= 851
            ? "25px"
            : Icon === "1" && W >= 561 && W <= 850
            ? "15px"
            : null,
        marginRight:
          Icon === "2" && W >= 851
            ? "25px"
            : Icon === "2" && W >= 561 && W <= 850
            ? "15px"
            : null,
        marginTop: Icon === "3" && W >= 561 && W <= 850 ? "740px" : null,
        boxShadow:
          Select === 1 && Icon === "1"
            ? "0 0.5rem 0.8rem rgba(0, 0, 0, 0.2)"
            : Select === 2 && Icon === "2"
            ? "0 0.5rem 0.8rem rgba(0, 0, 0, 0.2)"
            : Select === 3 && Icon === "3"
            ? "0 0.5rem 0.8rem rgba(0, 0, 0, 0.2)"
            : null,
      }}
      onClick={() => OnClick(Icon)}
    >
      <Box className={classess.I1}>
        <img src={Comma} alt="COMMA" className={classess.Comma} />
      </Box>
      <Box className={classess.I2}>
        <span>
          {Icon === "1"
            ? locales.COOLER
            : Icon === "2"
            ? locales.PACKAGE
            : locales.POMP}
        </span>
      </Box>
      <Box className={classess.I3}>
        <Box className={classess.Info}>
          <span className={classess.Title}>
            {Icon === "1"
              ? locales.TITLE01
              : Icon === "2"
              ? locales.TITLE02
              : locales.TITLE03}
          </span>
          <span className={classess.SubTitle}>
            {Icon === "3" ? locales.TAMIRAT : locales.SERVICE}
          </span>
        </Box>
        {Icon === "1" ? (
          <AcUnitRoundedIcon
            className={classess.Icons}
            sx={{
              backgroundColor: Select === 1 ? "#ef9f07" : "rgb(72, 58, 102)",
            }}
          />
        ) : Icon === "2" ? (
          <FlareRoundedIcon
            className={classess.Icons}
            sx={{
              backgroundColor: Select === 2 ? "#ef9f07" : "rgb(72, 58, 102)",
            }}
          />
        ) : (
          <ShowerRoundedIcon
            className={classess.Icons}
            sx={{
              backgroundColor: Select === 3 ? "#ef9f07" : "rgb(72, 58, 102)",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ItemService;
