import { Box } from "@mui/material";
import React, { useState } from "react";
import classess from "./Social.module.css";
import { SocialIcon } from "react-social-icons";

const Social = ({ MT, ML, W }) => {
  const [EL, setEL] = useState("");
  const phoneNumber = "1234567890";
  const message = "Hello, I'm interested in your services.";
  return (
    <Box
      className={classess.Social}
      sx={{ marginTop: MT, marginLeft: ML, width: W }}
    >
      {/* <span className={classess.Copyright}>
        Copyright © Dezember-2021. Alle Rechte vorbehalten. Erstellt von Ramin
        Maghsoodi
      </span> */}
      <Box>
        <SocialIcon
          url="instagram"
          bgColor={
            EL === "instagram_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "instagram_RED" ? "#ed013e" : "#444444"}
          style={{
            width: "35px",
            height: "35px",
            marginLeft: "-5px",
          }}
          href="https://www.instagram.com/khadamat__fani_parham?igsh=NWpxZDNmamZ4bHMw"
          onMouseEnter={() => setEL("instagram_RED")}
          onMouseLeave={() => setEL("instagram")}
        />
        <SocialIcon
          url="dribbble"
          bgColor="rgba(100,100,100,0.0)"
          fgColor="#ed013e"
          style={{ width: "35px", height: "35px", marginLeft: "5px" }}
          href="#"
        />
        <SocialIcon
          url="whatsapp"
          bgColor={
            EL === "linkedin_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "linkedin_RED" ? "#ed013e" : "#444444"}
          style={{ width: "35px", height: "35px", marginLeft: "5px" }}
          href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
            message
          )}`}
          onMouseEnter={() => setEL("linkedin_RED")}
          onMouseLeave={() => setEL("linkedin")}
        />

        <SocialIcon
          url="telegram"
          bgColor={
            EL === "youtube_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "youtube_RED" ? "#ed013e" : "#444444"}
          style={{ width: "35px", height: "35px", marginLeft: "5px" }}
          href="http://t.me/989124677180"
          onMouseEnter={() => setEL("youtube_RED")}
          onMouseLeave={() => setEL("youtube")}
        />
        {/* <SocialIcon
          url="pinterest"
          bgColor={
            EL === "pinterest_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "pinterest_RED" ? "#ed013e" : "#444444"}
          style={{ width: "35px", height: "35px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("pinterest_RED")}
          onMouseLeave={() => setEL("pinterest")}
        />
        <SocialIcon
          url="twitter"
          bgColor={
            EL === "twitter_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "twitter_RED" ? "#ed013e" : "#444444"}
          style={{ width: "35px", height: "35px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("twitter_RED")}
          onMouseLeave={() => setEL("twitter")}
        />
        <SocialIcon
          url="gitlab"
          bgColor={
            EL === "gitlab_RED" ? "rgba(90,90,90,0.1)" : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "gitlab_RED" ? "#ed013e" : "#444444"}
          style={{ width: "35px", height: "35px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("gitlab_RED")}
          onMouseLeave={() => setEL("gitlab")}
        />
        <SocialIcon
          url="email"
          bgColor={
            EL === "email_RED" ? "rgba(90,90,90,0.1)" : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "email_RED" ? "#ed013e" : "#444444"}
          style={{ width: "35px", height: "35px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("email_RED")}
          onMouseLeave={() => setEL("email")}
        /> */}
      </Box>
    </Box>
  );
};

export default Social;
