import React, { Fragment, useState } from "react";
import classess from "./Home.module.css";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import locales from "../../context/locales.json";
import NotesRoundedIcon from "@mui/icons-material/NotesRounded";
import RoofingIcon from "@mui/icons-material/Roofing";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LensBlurIcon from "@mui/icons-material/LensBlur";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Human from "../../assets/Human.png";
import Lines from "../../components/lines/Lines";
import Social from "../../components/Social/Social";
import Parham from "../../assets/Parham.png";
import ButtonIcon from "../../ui/button/ButtonIcon";
import Scroll from "../../assets/Scrool.png";

const Home = ({
  W,
  H,
  scrollService,
  scrollHome,
  scrollGallery,
  scrollContact,
  scrollCV,
}) => {
  const [Select, setSelect] = useState("M1");
  const options = [
    locales.T01,
    locales.T02,
    locales.T03,
    locales.CV,
    locales.T04,
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (ID) => {
    if (ID === 0) Home();
    if (ID === 1) Service();
    if (ID === 2) Gallery();
    if (ID === 3) CV();
    if (ID === 4) Contact();
    setAnchorEl(null);
  };
  const Service = () => {
    setSelect("M2");
    scrollService();
  };
  const Home = () => {
    setSelect("M1");
    scrollHome();
  };
  const Gallery = () => {
    setSelect("M3");
    scrollGallery();
  };
  const Contact = () => {
    setSelect("M4");
    scrollContact();
  };
  const CV = () => {
    setSelect("M5");
    scrollCV();
  };
  const ITEM_HEIGHT = 48;
  return (
    <Fragment>
      <Lines W={W} />
      <Box className={classess.Home}>
        <Box className={classess.Header}>
          <Box className={classess.Logo}>Parham</Box>
          <Box className={classess.Menu}>
            <span
              className={classess.Contact}
              style={{
                color: Select === "M4" ? "#ff001f" : null,
              }}
              onClick={() => Contact()}
            >
              {locales.M4}
            </span>
            <span
              className={classess.CV}
              style={{
                color: Select === "M5" ? "#ff001f" : null,
              }}
              onClick={() => CV()}
            >
              {locales.CV}
            </span>
            <span
              className={classess.Portfolio}
              style={{
                color: Select === "M3" ? "#ff001f" : null,
              }}
              onClick={() => Gallery()}
            >
              {locales.M3}
            </span>
            <span
              className={classess.Service}
              style={{
                color: Select === "M2" ? "#ff001f" : null,
              }}
              onClick={() => Service()}
            >
              {locales.M2}
            </span>
            <span
              className={classess.Main}
              style={{
                color: Select === "M1" ? "#ff001f" : null,
              }}
              onClick={() => Home()}
            >
              {locales.M1}
            </span>
          </Box>
          <Box className={classess.Stuff}>
            <IconButton
              sx={{
                position: "relative",
                width: "35px",
                height: "35px",
                top: "7px",
                marginRight: "0px",
                display: W >= 561 || H <= 400 ? "none" : "flex",
              }}
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <NotesRoundedIcon
                sx={{
                  color: "#7f8290",
                }}
              />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "23ch",
                  display: W >= 560 || H <= 400 ? "none" : null,
                },
              }}
            >
              {options.map((option, ID) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={() => handleClose(ID)}
                  sx={{
                    fontFamily: "A",
                    fontSize: "1.25rem",
                    direction: "rtl",
                    color: "rgb(2, 66, 74)",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "rgba(255,195,0,0.2)")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#FFF")
                  }
                >
                  {ID === 0 ? (
                    <RoofingIcon
                      sx={{ color: "rgb(2, 66, 74)", marginLeft: "5px" }}
                    />
                  ) : ID === 1 ? (
                    <SettingsSuggestIcon
                      sx={{ color: "rgb(2, 66, 74)", marginLeft: "5px" }}
                    />
                  ) : ID === 2 ? (
                    <LensBlurIcon
                      sx={{ color: "rgb(2, 66, 74)", marginLeft: "5px" }}
                    />
                  ) : ID === 3 ? (
                    <BeachAccessIcon
                      sx={{ color: "rgb(2, 66, 74)", marginLeft: "5px" }}
                    />
                  ) : (
                    <LocalPhoneIcon
                      sx={{ color: "rgb(2, 66, 74)", marginLeft: "5px" }}
                    />
                  )}
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        <Box className={classess.Bodi}>
          <Box className={classess.Info}>
            <Box className={classess.Data}>
              <Box className={classess.DataBox}>
                <Box className={classess.DB}></Box>
                <span className={classess.FANI}>{locales.FANI}</span>
                <img src={Parham} alt="PARHAM" className={classess.Parham} />
                <span className={classess.Detailes}>{locales.DETAILES}</span>
                <Box className={classess.Buttons}>
                  <ButtonIcon
                    Text={locales.M4}
                    Color="#ff023f"
                    Font="A"
                    FontSize="1.2rem"
                    FontWeight="normal"
                    BorderRadius="0px"
                    TextTransform="none"
                    Width="110px"
                    Height="35px"
                    LetterSpacing="-1px"
                    MarginTop="-15px"
                    Variant="contained"
                    MarginRight="0px"
                    MarginLeft="0px"
                    MarginBottom="50px"
                    OnClick={Contact}
                  />
                  <ButtonIcon
                    Text={locales.CV}
                    Color="#277305"
                    Font="A"
                    FontSize="1.2rem"
                    FontWeight="normal"
                    BorderRadius="0px"
                    TextTransform="none"
                    Width="110px"
                    Height="35px"
                    LetterSpacing="-1px"
                    MarginTop="-15px"
                    Variant="contained"
                    MarginRight="25px"
                    MarginLeft="0px"
                    MarginBottom="50px"
                    OnClick={CV}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classess.Social}>
              <Social
                MT={W <= 768 ? "0px" : "15px"}
                ML={W <= 768 ? "0px" : "100px"}
              />
            </Box>
          </Box>
          <Box className={classess.Human}>
            <img src={Human} alt="HUMAN" className={classess.Humans} />
            <img
              src={Scroll}
              alt="SCROLL"
              className={classess.Scroll}
              onClick={() => Service()}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Home;
