import React, { useState } from "react";
import classess from "./Portfolio.module.css";
import { Box } from "@mui/material";
import locales from "../../context/locales.json";
import LinePoint from "../../components/linepoint/LinePoint";
import ProductLarge from "../../components/product/ProductLarge";
import ProductSmall from "../../components/product/ProductSmall";

const Portfolio = ({ refGallery }) => {
  const [Select, setSelect] = useState("P1");
  const [Small, setSmall] = useState("150px");
  const [Large, setLarge] = useState("280px");

  return (
    <Box className={classess.Portfolio} ref={refGallery}>
      <span className={classess.Title}>{locales.T03}</span>
      <span className={classess.SubTitle}>{locales.SERVICE_SUBTITLE2}</span>
      <Box className={classess.ItemsMenus}>
        <Box className={classess.ItemsMenu} onClick={() => setSelect("P1")}>
          <span
            style={{
              color: Select === "P1" ? null : "gray",
              marginTop: Select === "P1" ? "0px" : "-7px",
            }}
          >
            {locales.POMP_}
          </span>
          {Select === "P1" ? <LinePoint /> : null}
        </Box>
        <Box className={classess.ItemsMenu} onClick={() => setSelect("P2")}>
          <span
            style={{
              color: Select === "P2" ? null : "gray",
              marginTop: Select === "P2" ? "0px" : "-7px",
            }}
          >
            {locales.PACKAGE_}
          </span>
          {Select === "P2" ? <LinePoint /> : null}
        </Box>
        <Box className={classess.ItemsMenu} onClick={() => setSelect("P3")}>
          <span
            style={{
              color: Select === "P3" ? null : "gray",
              marginTop: Select === "P3" ? "0px" : "-7px",
            }}
          >
            {locales.COOLER_}
          </span>
          {Select === "P3" ? <LinePoint /> : null}
        </Box>
      </Box>
      <Box className={classess.Items}>
        <Box className={classess.Items1}>
          <ProductLarge
            Title={
              Select === "P3"
                ? "آرامش"
                : Select === "P2"
                ? "استاد ایوبی"
                : "پمپ آب"
            }
            Subtitle={
              Select === "P3"
                ? "آرامش و آسایش"
                : Select === "P2"
                ? "استاد ایوبی در محل"
                : "نصب پمپ اب در باغ"
            }
            MT="0"
            MH={Large}
            Select={Select}
          />
          <ProductSmall
            Title={
              Select === "P3"
                ? "سرویس کاری"
                : Select === "P2"
                ? "پکیج کار"
                : "پمپ آب"
            }
            Subtitle={
              Select === "P3"
                ? "سرویس پنل بیرونی"
                : Select === "P2"
                ? "نصب پکیج و اتمام کار"
                : "نصب پمپ های آب صنعتی"
            }
            MT="20px"
            MH={Small}
            Select={Select}
          />
        </Box>
        <Box className={classess.Items2}>
          <ProductSmall
            Title={
              Select === "P3"
                ? "پنل هیبریدی"
                : Select === "P2"
                ? "تعمیر پکیج"
                : "پمپ آب خانگی"
            }
            Subtitle={
              Select === "P3"
                ? "نصب پنل هیبریدی کارخانجات"
                : Select === "P2"
                ? "سرویس و تعمیر پکیج در محل"
                : "پمپ های اب خانگی"
            }
            MT="0px"
            MH={Small}
            Select={Select}
          />
          <ProductLarge
            Title={
              Select === "P3"
                ? "پنــل"
                : Select === "P2"
                ? "پکیج و درخت"
                : "پمپ آب خانگی"
            }
            Subtitle={
              Select === "P3"
                ? "نصب پنل روی دیوار"
                : Select === "P2"
                ? "پکیج و درخت در یک نگاه"
                : "پمپ آب و رفع مشکل اصاسی"
            }
            MT="20px"
            MH={Large}
            Select={Select}
          />
        </Box>
        <Box className={classess.Items3}>
          <ProductLarge
            Title={
              Select === "P3"
                ? "تمیزکاری"
                : Select === "P2"
                ? "هیبریدی پکیج"
                : "پمپ"
            }
            Subtitle={
              Select === "P3"
                ? "آبپاشی پنل بیرونی در محل"
                : Select === "P2"
                ? "هیبریدی کردن پکیج ها در محل"
                : "پمپ آب و موسسات و شرکتها"
            }
            MT="0"
            MH={Large}
            Select={Select}
          />
          <ProductSmall
            Title={
              Select === "P3" ? "پنــل" : Select === "P2" ? "سرویس پکیج" : "پمپ"
            }
            Subtitle={
              Select === "P3"
                ? "نصب پنل بیرونی"
                : Select === "P2"
                ? "سرویس پکیج در محل"
                : "پمپ آب هیبریدی"
            }
            MT="20px"
            MH={Small}
            Select={Select}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Portfolio;
