import React, { Fragment } from "react";
import classess from "./ProductLarge.module.css";
import { Box } from "@mui/material";
import Kooler01 from "../../assets/kooler/04.jpg";
import Kooler02 from "../../assets/kooler/05.jpg";
import Kooler03 from "../../assets/kooler/06.jpg";
import Kooler04 from "../../assets/abgarmkon/01.jpg";
import Kooler05 from "../../assets/abgarmkon/02.jpg";
import Kooler06 from "../../assets/abgarmkon/03.jpg";
import Kooler07 from "../../assets/pomp/04.jpg";
import Kooler08 from "../../assets/pomp/05.jpg";
import Kooler09 from "../../assets/pomp/06.jpg";

const ProductSmall = ({ Select, Title, Subtitle, MT, MH }) => {
  return (
    <Fragment>
      <Box
        className={classess.ProductSmall}
        sx={{
          marginTop: MT,
          minHeight: MH,
        }}
      >
        {Select === "P3" && Title === "پنــل" ? (
          <img src={Kooler01} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P3" && Title === "پنل هیبریدی" ? (
          <img src={Kooler02} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P3" && Title === "سرویس کاری" ? (
          <img src={Kooler03} alt="" width="100%" minHeight="auto" />
        ) : null}

        {Select === "P2" && Title === "سرویس پکیج" ? (
          <img src={Kooler04} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P2" && Title === "تعمیر پکیج" ? (
          <img src={Kooler05} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P2" && Title === "پکیج کار" ? (
          <img src={Kooler06} alt="" width="100%" minHeight="auto" />
        ) : null}

        {Select === "P1" && Title === "پمپ آب" ? (
          <img src={Kooler07} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P1" && Title === "پمپ آب خانگی" ? (
          <img src={Kooler08} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P1" && Title === "پمپ" ? (
          <img src={Kooler09} alt="" width="100%" minHeight="auto" />
        ) : null}
      </Box>
      <span className={classess.ProductSmallTitle}>{Title}</span>
      <span className={classess.ProductSmallSubTitle}>{Subtitle}</span>
    </Fragment>
  );
};

export default ProductSmall;
