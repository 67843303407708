import { Box } from "@mui/material";
import React, { Fragment } from "react";
import classess from "./ProductLarge.module.css";
import Kooler01 from "../../assets/kooler/01.mp4";
import Kooler02 from "../../assets/kooler/02.jpg";
import Kooler03 from "../../assets/kooler/03.jpg";
import Kooler04 from "../../assets/abgarmkon/04.jpg";
import Kooler05 from "../../assets/abgarmkon/05.jpg";
import Kooler06 from "../../assets/abgarmkon/06.jpg";
import Kooler07 from "../../assets/pomp/01.jpg";
import Kooler08 from "../../assets/pomp/02.jpg";
import Kooler09 from "../../assets/pomp/03.jpg";

const ProductLarge = ({ Select, Title, Subtitle, MT, MH }) => {
  return (
    <Fragment>
      <Box
        className={classess.ProductLarge}
        sx={{
          marginTop: MT,
          minHeight: MH,
        }}
      >
        {Select === "P3" && Title === "تمیزکاری" ? (
          <video width="100%" minHeight="auto" controls>
            <source src={Kooler01} type="video/mp4" />
          </video>
        ) : null}
        {Select === "P3" && Title === "پنــل" ? (
          <img src={Kooler02} alt="" width="100%" minHeight="auto" />
        ) : null}{" "}
        {Select === "P3" && Title === "آرامش" ? (
          <img src={Kooler03} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P2" && Title === "هیبریدی پکیج" ? (
          <img src={Kooler04} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P2" && Title === "پکیج و درخت" ? (
          <img src={Kooler05} alt="" width="100%" minHeight="auto" />
        ) : null}{" "}
        {Select === "P2" && Title === "استاد ایوبی" ? (
          <img src={Kooler06} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P1" && Title === "پمپ آب" ? (
          <img src={Kooler07} alt="" width="100%" minHeight="auto" />
        ) : null}
        {Select === "P1" && Title === "پمپ آب خانگی" ? (
          <img src={Kooler08} alt="" width="100%" minHeight="auto" />
        ) : null}{" "}
        {Select === "P1" && Title === "پمپ" ? (
          <img src={Kooler09} alt="" width="100%" minHeight="auto" />
        ) : null}
      </Box>
      <span className={classess.ProductLargeTitle}>{Title}</span>
      <span className={classess.ProductLargeSubTitle}>{Subtitle}</span>
    </Fragment>
  );
};

export default ProductLarge;
