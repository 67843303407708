import { Box } from "@mui/material";
import React from "react";
import classess from "./Loading.module.css";
import loading from "../../assets/loading.gif";

const Loading = () => {
  return (
    <Box className={classess.Loading}>
      <img src={loading} alt="Loading ... " className={classess.LoadingImg} />
      <span>LOADING ...</span>
    </Box>
  );
};

export default Loading;
