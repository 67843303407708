import React from "react";
import classess from "./LinePoint.module.css";
import { Box } from "@mui/material";

const LinePoint = () => {
  return (
    <Box className={classess.LinePoint}>
      <Box className={classess.LP}></Box>
      <Box className={classess.LP}></Box>
      <Box className={classess.LP}></Box>
    </Box>
  );
};

export default LinePoint;
